import { FC } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import s from './Memo.module.scss';

interface MemoProps {
  handleDownload: () => void;
}

const Memo: FC<MemoProps> = (props) => {
  const { handleDownload } = props;

  return (
    <>
      <Flex
        gap="8"
        className={s.download}
        align="center"
        justify="space-between"
        onClick={handleDownload}>
        <Flex gap="8" align="center">
          <div className={cn(s.icon)}>
            <Icon name="docTypeEmpty" />
          </div>
          <Typography.Text size="14" weight="500">
            {t('profile_rating_download_memo')}
          </Typography.Text>
        </Flex>
        <Icon name="download" color="var(--color-primary-6)" />
      </Flex>
      <div className={s.memo}>
        <Typography.Title level={5}>
          В рейтинг лучших линейных ИТР входит ТОП-1000 сотрудников,{' '}
          <Typography.Text size="16">
            это около 40% от численности всех Линейных ИТР
          </Typography.Text>
        </Typography.Title>
        <Typography.Text size="14">
          Чтобы сформировать рейтинг ТОП сотрудников были разработаны 7 универсальных критериев, за
          которые сотруднику начисляются баллы от 0,5 до 2 Максимальная сумма баллов по всем
          показателям – 10 баллов
        </Typography.Text>
        <Typography.Title level={5}>
          В рейтинге сотрудники не разделены по конкретным местам (от 1 до 1000), а делятся на 3
          большие группы:
        </Typography.Title>
        <Typography.Text size="14">
          <Typography.Text size="14" weight="600">
            — ТОП-300
          </Typography.Text>{' '}
          – 300 сотрудников с самыми высокими баллами в начале рейтинга
        </Typography.Text>
        <Typography.Text size="14">
          <Typography.Text size="14" weight="600">
            — ТОП-700
          </Typography.Text>{' '}
          – еще плюс 400 сотрудников, оказавшихся в середине реестра лучших
        </Typography.Text>
        <Typography.Text size="14">
          <Typography.Text size="14" weight="600">
            — ТОП-1000
          </Typography.Text>{' '}
          – еще плюс 300 сотрудников, замыкающих реестр лучших
        </Typography.Text>
        <Typography.Title level={5}>Зачем мне нужен рейтинг?</Typography.Title>
        <Typography.Text size="14">
          Чем выше рейтинг работника – тем более широкий доступ к мотивационным программам он может
          получить (полис ДМС, поездка в санаторий, поездка на форум лучших руководителей на Строй
          Герой, поездка детей в Петербург на Елку лучших, Детский отдых в оздоровительном лагере)
        </Typography.Text>
        <Typography.Title level={5}>Как я могу повлиять на свой рейтинг?</Typography.Title>
        <Typography.Text size="14">
          Практически на каждый показатель рейтинга (кроме стажа и наличия наград) можно повлиять.
          Вот несколько советов как влиять на показатели, от которых зависит рейтинг:
        </Typography.Text>
        <Typography.Text size="14">
          1. Регулярно запрашивать обратную связь у своего руководителя – он также вас оценивает
        </Typography.Text>
        <Typography.Text size="14">
          2. Не игнорировать письма о прохождении оценки личностных и профессиональных компетенций
          (2 раза в год)
        </Typography.Text>
        <Typography.Text size="14">
          3. Регулярно участвовать в выполнении ОВЗ и контролировать предоставление отчетности об
          этом
        </Typography.Text>
        <Typography.Text size="14">
          4. Соблюдать график вахтования и вырабатывать годовую норму
        </Typography.Text>
        <Typography.Title level={5}>Как часто обновляется рейтинг?</Typography.Title>
        <Typography.Text size="14">
          Рейтинг обновляется 2 раза в год – в период с 1 по 30 июня и с 1 по 30 декабря При этом
          оценочные процедуры проводятся в течение всего года: также, два раза для каждого
          сотрудника
        </Typography.Text>
        <Typography.Title level={5}>
          Подробности о рейтинге, его критериях и порядке формирования можно узнать из памятки во
          вложении
        </Typography.Title>
      </div>
    </>
  );
};

export default Memo;
