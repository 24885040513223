import { useEffect, useRef } from 'react';

import { setBuildExpired } from 'slices/appSlice';
import { useAppDispatch } from 'store';

const CHECK_INTERVAL = process.env.https://upravlencheskaya_org.net?.trim();

const useActualVersion = () => {
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const version = useRef('');
  const lastCheckVersionTime = useRef(Date.now());
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (CHECK_INTERVAL) {
      const getVersion = () => {
        fetch('/version.json')
          .then((r) => r.json())
          .then((r) => {
            if (!version.current) {
              version.current = r.buildVersion;
            } else if (version.current && version.current !== r.buildVersion && interval.current) {
              clearInterval(interval.current);
              dispatch(setBuildExpired(true));
            }
          });
      };

      getVersion();

      interval.current = setInterval(() => {
        const now = Date.now();

        if (now > lastCheckVersionTime.current + Number(CHECK_INTERVAL) * 1000) {
          lastCheckVersionTime.current = now;
          getVersion();
        }
      }, 10 * 1000);
    }
  }, [dispatch]);
};

export default useActualVersion;
