import { useState } from 'react';

import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor, HttpHeaders } from '@capacitor/core';
import {
  Directory,
  DownloadFileOptions,
  DownloadFileResult,
  Filesystem,
} from '@capacitor/filesystem';
import { useDispatch } from 'react-redux';

import { handleOpenDownloadErrorModal } from 'slices/downloadErrorModalSlice';

import { userManager } from './keycloak';

const getFileContentType = async (url: string, headers?: HttpHeaders) => {
  try {
    const response = await fetch(url, {
      method: 'HEAD',
      headers,
    });

    return response.headers.get('Content-Type');
  } catch (error) {
    console.error('Ошибка при получении Content-Type:', error);
    return null;
  }
};

const useOpenFileByUrl = () => {
  const [loadingKey, setLoadingKey] = useState<string>('');

  const dispatch = useDispatch();

  const handleOpenErrorModal = () => dispatch(handleOpenDownloadErrorModal(true));

  const handleOpenFile = async (handleOpenParams: {
    fileKey?: string;
    url: string;
    withAuth?: boolean;
    myEmployeeId?: string;
    defaultFileName?: string;
    getFileExtensionFromResponse?: boolean;
  }) => {
    const {
      getFileExtensionFromResponse,
      fileKey = '',
      url,
      withAuth,
      myEmployeeId,
      defaultFileName,
    } = handleOpenParams;

    setLoadingKey(fileKey);
    let fileName = url.substring(url.lastIndexOf('/') + 1);

    const progress = await Filesystem.addListener('progress', (progress) => {
      console.log('progress', Math.round((progress.bytes / progress.contentLength) * 100));
    });

    const headers: { Authorization?: string; 'X-My-Employee-Id'?: string } = {};

    if (withAuth) {
      const user = await userManager.getUser();
      const token = user?.access_token as string;

      headers['Authorization'] = `Bearer ${token}`;
    }

    if (myEmployeeId) {
      headers['X-My-Employee-Id'] = myEmployeeId;
    }

    if (!defaultFileName && !fileName.includes('.')) {
      const fileMeta = await fetch(url.replace('download', 'meta'), {
        headers,
      }).then((r) => r.json());
      fileName = fileMeta.fileName;
    }

    const options: DownloadFileOptions = {
      url: url,
      path: fileName,
      progress: true,
      directory: Directory.Data,
      responseType: 'blob',
      headers,
    };

    try {
      if (Capacitor.getPlatform() === 'web') {
        return fetch(url, {
          method: 'GET',
          credentials: 'include',
          headers: options.headers,
        })
          .then((res) => {
            if (res.ok) {
              return res;
            }

            throw new Error();
          })
          .then((response) => {
            const contentDisposition = response.headers.get('Content-Disposition');
            let fileNameFromBlob = '';

            if (contentDisposition) {
              const match = contentDisposition.match(/filename="?([^"]+)"?/);
              if (match && match[1]) {
                fileNameFromBlob = match[1];
              }
            }

            return response.blob().then((blob) => ({ blob, fileNameFromBlob }));
          })
          .then(({ blob, fileNameFromBlob }) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            if (getFileExtensionFromResponse && fileNameFromBlob) {
              const splitFileName = fileNameFromBlob.split('.');
              a.download = `${defaultFileName}.${splitFileName[splitFileName.length - 1]}`;
            } else {
              a.download = defaultFileName || fileName;
            }
            document.body.appendChild(a);

            a.click();

            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          });
      } else {
        const contentType = await getFileContentType(options.url, options.headers);
        const response: DownloadFileResult = await Filesystem.downloadFile(options);
        const path = response.path;

        if (contentType)
          FileOpener.open({
            filePath: path!,
            contentType,
          })
            .then(() => console.log('File is opened'))
            .catch((e) => console.log('Error opening file', e))
            .finally(() => {
              progress.remove();
              console.log('clear download');
            });
      }
    } catch (e) {
      handleOpenErrorModal();
    } finally {
      setLoadingKey('');
    }
  };

  return {
    loadingKey,
    handleOpenFile,
  };
};

export default useOpenFileByUrl;
