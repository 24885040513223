import { FC, PropsWithChildren } from 'react';

import classNames from 'classnames';

import { Flex, Icon, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import s from './surveyLabel.module.scss';

export enum LABEL_TYPES {
  COMPLETED = 'completed',
  MANDATORY = 'mandatory',
  PRIMARY = 'primary',
}

interface SurveysLabelProps extends PropsWithChildren {
  type: LABEL_TYPES;
  icon: TIconsLiteral;
}

const SurveyLabel: FC<SurveysLabelProps> = (props) => {
  const { type, icon, children } = props;

  return (
    <Flex gap="4" align="center" className={classNames(s.container, s[type])}>
      <Icon name={icon} />
      <Typography.Text size="12" weight="500">
        {children}
      </Typography.Text>
    </Flex>
  );
};

export default SurveyLabel;
