import { createSlice } from '@reduxjs/toolkit';
import { PaginationPropsType } from 'gazprom-common-lib';
import { TCommunityMeet } from 'types/community.types';

export const SLICE_NAME = 'communityMeetsSlice';

export type TCommunityMeetsSliceState = PaginationPropsType & {
  meets: TCommunityMeet[];
};

const DEFAULT_PAGINATION = { page: 1, size: 10 };

const initialState: TCommunityMeetsSliceState = {
  meets: [],
  ...DEFAULT_PAGINATION,
};

export const communityMeetsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPaginationAndMeets(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
      state.meets = [];
    },
    resetPagination(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
    },
    addMeets(state, action) {
      state.meets = state.meets.concat(action.payload);
    },
    addPage(state) {
      state.page = state.page + 1;
    },
    addRemoveLike(state, action) {
      state.meets = state.meets.map((meet: TCommunityMeet): TCommunityMeet => {
        if (meet.code === action.payload) {
          const likesCnt = meet.liked ? meet.likeCount - 1 : meet.likeCount + 1;

          return {
            ...meet,
            liked: !meet.liked,
            likeCount: likesCnt,
          };
        }

        return meet;
      });
    },
    addRemoveParticipation(state, action) {
      const { isParticipant, id } = action.payload;

      state.meets = state.meets.map((meet): TCommunityMeet => {
        if (meet.id === id) {
          meet.isParticipant = isParticipant;
          isParticipant ? (meet.countMembers += 1) : (meet.countMembers -= 1);
        }

        return meet;
      });

    },
  },
});

export const {
  addPage,
  addMeets,
  addRemoveLike,
  addRemoveParticipation,
  resetPagination,
  resetPaginationAndMeets
} = communityMeetsSlice.actions;

export default communityMeetsSlice.reducer;
