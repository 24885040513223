import { ReactNode, useMemo } from 'react';

import cn from 'classnames';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { Flex, Icon, Progress, Typography } from 'gazprom-ui-lib';

import SurveyLabel from 'components/survey-label';
import { LABEL_TYPES } from 'components/survey-label/surveyLabel';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import { countAnswers } from '../anketolog.utils';
import s from './AnketologCard.module.scss';

interface Props {
  id: string;
  to: string;
  title: string;
  anketologId: string;
  revisionId: number;
  isCompleted: boolean | null;
  countAllQuestions: number;
  isRequired?: boolean | null;
  adittionalTags?: ReactNode;
  countCompletedAnswers?: number | null;
  withoutMargin?: boolean;
}

const AnketologCard = (props: Props) => {
  const {
    to,
    id,
    anketologId,
    revisionId,
    title,
    isCompleted,
    isRequired,
    adittionalTags,
    countAllQuestions,
    countCompletedAnswers,
    withoutMargin,
  } = props;

  const [selectedEmployee] = useFullSelectedEmployee();

  const [storageValue] = useSecureStorage(
    `${SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SURVEY_PROGRESS_ID}_${anketologId}_${revisionId}_${id}_${selectedEmployee?.id}`,
    '',
  );

  const percent = useMemo(() => {
    const answersFromStorage = storageValue ? countAnswers(JSON.parse(storageValue)) : 0;

    return ((countCompletedAnswers || answersFromStorage) / countAllQuestions) * 100;
  }, [countAllQuestions, countCompletedAnswers, storageValue]);

  const formatRenderer = (percent?: number) => {
    if (isCompleted) {
      return <Icon name={'ok'} color="var(--color-success)" size={24} />;
    }

    return (
      <Typography.Text size="14" weight="500">
        {percent?.toFixed()}%
      </Typography.Text>
    );
  };

  return (
    <Link to={to} className={cn(s.card, { [s.withoutMargin]: withoutMargin })}>
      <Flex vertical gap="12">
        <Flex gap="8" justify="space-between">
          <Typography.Text size="14" weight="500">
            {title}
          </Typography.Text>
          <Progress
            strokeColor={isCompleted ? 'var(--color-success)' : 'var(--color-primary-6)'}
            strokeWidth={20}
            type="dashboard"
            steps={{ count: 8, gap: 3 }}
            format={formatRenderer}
            percent={percent}
            size={74}
          />
        </Flex>
      </Flex>
      <Flex gap="8" wrap="wrap">
        {isCompleted && (
          <SurveyLabel icon="ok" type={LABEL_TYPES.COMPLETED}>
            {t('common_passed_single')}
          </SurveyLabel>
        )}
        {isRequired && !isCompleted && (
          <SurveyLabel icon="time" type={LABEL_TYPES.MANDATORY}>
            {t('survey_label_mandatory')}
          </SurveyLabel>
        )}
        {adittionalTags}
      </Flex>
    </Link>
  );
};

export default AnketologCard;
