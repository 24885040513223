import { lazy } from 'react';

import { RouteConfig } from '../routes';
import {
  DOCUMENTS_ASSIGNMENT_SIGN_LIST_ROUTE,
  DOCUMENTS_BUSINESS_TRIP_APPROVERS_LIST_ROUTE,
  DOCUMENTS_BUSINESS_TRIP_CREATE_ROUTE,
  DOCUMENTS_BUSINESS_TRIP_DETAILS_CANCEL_ROUTE,
  DOCUMENTS_BUSINESS_TRIP_DETAILS_EDIT_ROUTE,
  DOCUMENTS_BUSINESS_TRIP_DETAILS_ROUTE,
  DOCUMENTS_BUSINESS_TRIP_ROUTE,
  DOCUMENTS_CERTIFICATE_ROUTE,
  DOCUMENTS_CREATE_CERTIFICATES_ROUTE,
  DOCUMENTS_INQUIRIES_CHOOSE_CATEGORY_ROUTE,
  DOCUMENTS_INQUIRIES_DETAILS_ATTRIBUTES_ROUTE,
  DOCUMENTS_INQUIRIES_DETAILS_ROUTE,
  DOCUMENTS_INQUIRIES_LEADERS_ROUTE,
  DOCUMENTS_INQUIRIES_REQUEST_ROUTE,
  DOCUMENTS_INQUIRIES_ROUTE,
  DOCUMENTS_INQUIRIES_SEARCH_CATEGORY_ROUTE,
  DOCUMENTS_ROUTE,
  DOCUMENTS_SIGN_CODE_ROUTE,
  DOCUMENTS_SIGN_LIST_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_CATEGORY_DETAILS_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_CHOOSE_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_DETAILS_ATTRIBUTES_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_LEADERS_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_PROGRAM_APPLY_FROM_DRAFT_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_PROGRAM_APPLY_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_PROGRAM_DETAILS_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_ROUTE,
  DOCUMENTS_STATEMENTS_DETAILS_ATTRIBUTES_ROUTE,
  DOCUMENTS_STATEMENTS_DETAILS_ROUTE,
  DOCUMENTS_STATEMENTS_LEADERS_ROUTE,
  DOCUMENTS_STATEMENTS_ROUTE,
  DOCUMENTS_STATEMENT_CHOOSE_CATEGORY_ROUTE,
  DOCUMENTS_STATEMENT_REQUEST_ROUTE,
  DOCUMENTS_STATEMENT_SEARCH_CATEGORY_ROUTE,
  DOCUMENTS_TASKS_DETAILS_ROUTE,
  DOCUMENTS_TOP_MANAGEMENT_BY_STAGE_ROUTE,
} from './list';

const Documents = lazy(() => import('pages/documents'));
const DocumentsAssignmentDetails = lazy(
  () => import('pages/documents/documents-assignment-details'),
);
const DocumentsAssignmentSignList = lazy(
  () => import('pages/documents/documents-assignment-sign-list'),
);
const DocumentsBusinessTrip = lazy(() => import('pages/documents/documents-business-trip'));
const DocumentsBusinessTripCreate = lazy(
  () => import('pages/documents/documents-business-trip/documents-business-trip-create'),
);
const DocumentsBusinessTripDetails = lazy(
  () => import('pages/documents/documents-business-trip/documents-business-trip-details'),
);
const DocumentsBusinessTripApproversList = lazy(
  () =>
    import(
      'pages/documents/documents-business-trip/documents-business-trip-details/documents-business-trip-approvers-list'
    ),
);
const DocumentsBusinessTripDetailsCancel = lazy(
  () =>
    import(
      'pages/documents/documents-business-trip/documents-business-trip-details/documents-business-trip-details-cancel'
    ),
);
const DocumentsBusinessTripDetailsEdit = lazy(
  () =>
    import(
      'pages/documents/documents-business-trip/documents-business-trip-details/documents-business-trip-details-edit'
    ),
);
const DocumentsCertificate = lazy(() => import('pages/documents/documents-certificate'));
const DocumentsCreateCertificate = lazy(
  () => import('pages/documents/documents-create-certificate'),
);
const DocumentsCreateChooseCategory = lazy(
  () => import('pages/documents/documents-create-choose-category'),
);
const DocumentsCreateRequest = lazy(() => import('pages/documents/documents-create-request'));
const DocumentsStatementSearch = lazy(
  () => import('pages/documents/documents-create-search-category'),
);
const DocumentsInquiries = lazy(() => import('pages/documents/documents-inquiries'));
const DocumentsRequestLeaders = lazy(() => import('pages/documents/documents-request-leaders'));
const DocumentsRequestsDetails = lazy(() => import('pages/documents/documents-requests-details'));
const DocumentsRequestsDetailsAttributes = lazy(
  () => import('pages/documents/documents-requests-details/documents-requests-details-attributes'),
);
const DocumentsSignCode = lazy(() => import('pages/documents/documents-sign-code'));
const DocumentsSignList = lazy(() => import('pages/documents/documents-sign-list'));
const DocumentsSocialPolicy = lazy(() => import('pages/documents/documents-social-policy'));
const DocumentsStatements = lazy(() => import('pages/documents/documents-statements'));
const DocumentsTopManagement = lazy(() => import('pages/documents/documents-top-management'));

// ! SocialPolicy /guide -> docs
const DocumentsSocialPolicyChoose = lazy(
  () => import('pages/documents/documents-social-policy-choose'),
);
const DocumentsSocialPolicyCategoryDetails = lazy(
  () => import('pages/documents/documents-social-policy-choose/documents-social-policy-category'),
);
const DocumentsSocialPolicyCategoryProgram = lazy(
  () =>
    import(
      'pages/documents/documents-social-policy-choose/documents-social-policy-category/documents-social-policy-category-program'
    ),
);
const DocumentsSocialPolicyApply = lazy(
  () => import('pages/documents/documents-social-policy-choose/guide-social-policy-apply'),
);

export const excludedRoutesForPreUser = [
  DOCUMENTS_INQUIRIES_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_ROUTE,
  DOCUMENTS_STATEMENTS_ROUTE,
];

export const documentsRoutes: RouteConfig[] = [
  {
    path: DOCUMENTS_ROUTE,
    element: <Documents />,
  },
  {
    path: DOCUMENTS_INQUIRIES_ROUTE,
    element: <DocumentsInquiries />,
  },
  {
    path: DOCUMENTS_STATEMENTS_ROUTE,
    element: <DocumentsStatements />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_ROUTE,
    element: <DocumentsSocialPolicy />,
  },
  {
    path: DOCUMENTS_CERTIFICATE_ROUTE,
    element: <DocumentsCertificate />,
  },
  {
    path: DOCUMENTS_CREATE_CERTIFICATES_ROUTE,
    element: <DocumentsCreateCertificate />,
  },
  {
    path: DOCUMENTS_STATEMENT_CHOOSE_CATEGORY_ROUTE,
    element: <DocumentsCreateChooseCategory />,
  },
  {
    path: DOCUMENTS_INQUIRIES_CHOOSE_CATEGORY_ROUTE,
    element: <DocumentsCreateChooseCategory />,
  },
  {
    path: DOCUMENTS_STATEMENT_REQUEST_ROUTE,
    element: <DocumentsCreateRequest />,
  },
  {
    path: DOCUMENTS_INQUIRIES_REQUEST_ROUTE,
    element: <DocumentsCreateRequest />,
  },
  {
    path: DOCUMENTS_STATEMENT_SEARCH_CATEGORY_ROUTE,
    element: <DocumentsStatementSearch />,
  },
  {
    path: DOCUMENTS_INQUIRIES_SEARCH_CATEGORY_ROUTE,
    element: <DocumentsStatementSearch />,
  },
  {
    path: DOCUMENTS_SIGN_LIST_ROUTE,
    element: <DocumentsSignList />,
  },
  {
    path: DOCUMENTS_SIGN_CODE_ROUTE,
    element: <DocumentsSignCode />,
  },
  {
    path: DOCUMENTS_INQUIRIES_DETAILS_ROUTE,
    element: <DocumentsRequestsDetails />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE,
    element: <DocumentsRequestsDetails />,
  },
  {
    path: DOCUMENTS_STATEMENTS_DETAILS_ROUTE,
    element: <DocumentsRequestsDetails />,
  },
  {
    path: DOCUMENTS_TASKS_DETAILS_ROUTE,
    element: <DocumentsAssignmentDetails />,
  },
  {
    path: DOCUMENTS_ASSIGNMENT_SIGN_LIST_ROUTE,
    element: <DocumentsAssignmentSignList />,
  },
  {
    path: DOCUMENTS_INQUIRIES_DETAILS_ATTRIBUTES_ROUTE,
    element: <DocumentsRequestsDetailsAttributes />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_DETAILS_ATTRIBUTES_ROUTE,
    element: <DocumentsRequestsDetailsAttributes />,
  },
  {
    path: DOCUMENTS_STATEMENTS_DETAILS_ATTRIBUTES_ROUTE,
    element: <DocumentsRequestsDetailsAttributes />,
  },
  {
    path: DOCUMENTS_INQUIRIES_LEADERS_ROUTE,
    element: <DocumentsRequestLeaders />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_LEADERS_ROUTE,
    element: <DocumentsRequestLeaders />,
  },
  {
    path: DOCUMENTS_STATEMENTS_LEADERS_ROUTE,
    element: <DocumentsRequestLeaders />,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIP_ROUTE,
    element: <DocumentsBusinessTrip />,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIP_CREATE_ROUTE,
    element: <DocumentsBusinessTripCreate />,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIP_DETAILS_ROUTE,
    element: <DocumentsBusinessTripDetails />,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIP_APPROVERS_LIST_ROUTE,
    element: <DocumentsBusinessTripApproversList />,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIP_DETAILS_EDIT_ROUTE,
    element: <DocumentsBusinessTripDetailsEdit />,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIP_DETAILS_CANCEL_ROUTE,
    element: <DocumentsBusinessTripDetailsCancel />,
  },
  {
    path: DOCUMENTS_TOP_MANAGEMENT_BY_STAGE_ROUTE,
    element: <DocumentsTopManagement />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_CHOOSE_ROUTE,
    element: <DocumentsSocialPolicyChoose />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_CATEGORY_DETAILS_ROUTE,
    element: <DocumentsSocialPolicyCategoryDetails />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_PROGRAM_DETAILS_ROUTE,
    element: <DocumentsSocialPolicyCategoryProgram />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_PROGRAM_APPLY_ROUTE,
    element: <DocumentsSocialPolicyApply />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_PROGRAM_APPLY_FROM_DRAFT_ROUTE,
    element: <DocumentsSocialPolicyApply />,
  },
];
