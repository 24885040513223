export const GUIDE_ROUTE = '/guide';
export const GUIDE_BY_ID_ROUTE = `${GUIDE_ROUTE}/:id`;
export const GUIDE_MANAGEMENT_ROUTE = `${GUIDE_ROUTE}/management`;
export const GUIDE_EDUCATION_ROUTE = `${GUIDE_ROUTE}/education`;

export const GUIDE_SETTLEMENT_ROUTE = `${GUIDE_ROUTE}/guide-settlement`;
export const GUIDE_SETTLEMENT_RATING_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/rating`;
export const GUIDE_SETTLEMENT_SURVEY_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/survey`;
export const GUIDE_SETTLEMENT_SURVEY_BY_ID_ROUTE = `${GUIDE_SETTLEMENT_SURVEY_ROUTE}/:anketologId/:revisionId/:anketaLinkId/:surveyCompletedEmployeeId?`;
export const GUIDE_SETTLEMENT_REGIONS = `${GUIDE_SETTLEMENT_ROUTE}/regions`;
export const GUIDE_SETTLEMENT_BY_REGION = `${GUIDE_SETTLEMENT_ROUTE}/regions/:regionId`;
export const GUIDE_GUIDE_SETTLEMENT_POSTER_DETAILS_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/:posterId`;
export const getSettlementPosterWithIdWithIdRoute = (posterId: string) =>
  `${GUIDE_SETTLEMENT_ROUTE}/${posterId}`;
export const GUIDE_MEMO_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/memo`;
export const GUIDE_MAP_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/map`;
// TODO.Refactor GUIDE_MY_SETTLEMENTS_ROUTE нужен?
export const GUIDE_MY_SETTLEMENTS_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/my-settlements`;
export const GUIDE_QR_CODE_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/qr-code`;
export const GUIDE_SELECT_SETTLEMENT_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/select-settlement`;
export const GUIDE_IMPORTANT_CONTACTS_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/important-contacts`;
// TODO.Refactor legacy route - GUIDE_MY_TEMPORARY_RESIDENTIAL_CAMP_ROUTE?
export const GUIDE_MY_TEMPORARY_RESIDENTIAL_CAMP_ROUTE = `${GUIDE_SETTLEMENT_ROUTE}/temporary-residential-camp`;

export const GUIDE_ACTIVATE_SETTLEMENT_BY_ID = `${GUIDE_ROUTE}/activate/:settlementId`;

export const getSettlementsByRegionIdRoute = (regionId: number) =>
  `${GUIDE_SETTLEMENT_REGIONS}/${regionId}`;

export const getGuideSurveyRoute = (
  anketologId: string,
  revisionId: number,
  anketaLinkId: string,
  surveyCompletedEmployeeId: string | null,
) => {
  const completedEmployeeIdSlug = surveyCompletedEmployeeId ? `/${surveyCompletedEmployeeId}` : '';

  return `${GUIDE_SETTLEMENT_SURVEY_ROUTE}/${anketologId}/${revisionId}/${anketaLinkId}${completedEmployeeIdSlug}`;
};
