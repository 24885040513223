import { createSlice } from '@reduxjs/toolkit';
import { CommunityType, PaginationPropsType } from 'gazprom-common-lib';

export const SLICE_NAME = 'communitiesSlice';

export type CommunitySliceState = PaginationPropsType & {
  communitiesToShow: CommunityType[];
};

const DEFAULT_PAGINATION = { page: 1, size: 10 };

const initialState: CommunitySliceState = {
  communitiesToShow: [],
  ...DEFAULT_PAGINATION,
};

export const communitiesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPaginationTagsAndCommunities(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
      state.communitiesToShow = [];
    },
    addCommunities(state, action) {
      state.communitiesToShow = state.communitiesToShow.concat(action.payload);
    },
    resetCommunities(state) {
      state.communitiesToShow = [];
    },
    resetPagination(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
    },
    addPage(state) {
      state.page = state.page + 1;
    },
    updateDetails(state, action) {
      state.communitiesToShow = state.communitiesToShow.map((community) => {
        if (community.id === action.payload.id) {
          return {
            ...community,
            ...action.payload,
          };
        }

        return community;
      });
    },
    changeSubscription(state, action) {
      state.communitiesToShow = state.communitiesToShow.map((community) => {
        if (community.id === action.payload.id) {
          const status = action.payload.status;
          return {
            ...community,
            status,
            countMembers: status === 'SUBSCRIBER' ? community.countMembers + 1 : community.countMembers - 1
          };
        }

        return community;
      });
    },
  },
});

export const {
  addPage,
  addCommunities,
  resetPaginationTagsAndCommunities,
  resetCommunities,
  resetPagination,
  changeSubscription,
  updateDetails,
} = communitiesSlice.actions;

export default communitiesSlice.reducer;
