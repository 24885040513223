import { useState } from 'react';

import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import useSecureStorage from 'utils/useSecureStorage.hook';

import {
  useGetAllSurveysQuery,
  useGetEmployeeSurveysQuery,
} from 'services/survey/surveyApiService';
import { GetEmployeeSurveysResponse } from 'services/survey/surveyApiService.types';

import { Segmented, SegmentedProps, Typography } from 'gazprom-ui-lib';

import { Divider } from 'components/divider';
import { FirstTimeVisit } from 'components/first-time-visit';
import AnketologCard from 'containers/anketolog/anketolog-card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import SettlementRating from './containers/settlement-rating';
import SurveysEmpty from './surveys-empty';
import s from './surveys.module.scss';

const headerChildren = (
  <>
    <Header.GoBack to={PROFILE_ROUTE} />
    <Header.Label label={t('survey_surveys_questionnaires')} />
  </>
);

const Surveys = () => {
  const [selectedEmployee] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SELECTED_EMPLOYEE,
    '',
  );
  const employerId = selectedEmployee;
  const { data: allSurveys, isLoading: isAllSurveysLoading } = useGetAllSurveysQuery();
  const { data: employeeSurveys, isLoading: isEmployeeSurveysLoading } = useGetEmployeeSurveysQuery(
    { myEmployeeId: employerId },
  );
  const SEGMENT_OPTIONS = [
    {
      label: (
        <div className={s.segmentOption}>
          <Typography.Title level={5}>{t('common_to_passage')}</Typography.Title>
          <Typography.Text size="10">{employeeSurveys?.length ?? 0}</Typography.Text>
        </div>
      ),
      value: 'all',
    },
    {
      label: (
        <div className={s.segmentOption}>
          <Typography.Title level={5}>{t('common_passed')}</Typography.Title>
          <Typography.Text size="10">{allSurveys?.length ?? 0}</Typography.Text>
        </div>
      ),
      value: 'passed',
    },
  ];
  const [optionAll] = SEGMENT_OPTIONS;
  const [selectedSegment, setSelectedSegment] = useState(optionAll.value);

  const handleSegmentChange: SegmentedProps['onChange'] = (segment) =>
    setSelectedSegment(segment.toString());

  const renderTabs = (data: GetEmployeeSurveysResponse | undefined) => {
    if (data?.length) {
      return data.map((surveyParams, index) => {
        const {
          id,
          anketaLinkId,
          anketologId,
          revisionId,
          completedAnswerCount,
          isMandatory,
          questionsCount,
          isCompleted,
          name,
        } = surveyParams;

        const key = `${id}_${index}_${selectedSegment}`;
        const link = `${anketologId}/${revisionId.toString()}/${anketaLinkId}`;

        return (
          <AnketologCard
            key={key}
            id={anketaLinkId}
            to={link}
            title={name}
            anketologId={anketologId}
            revisionId={revisionId}
            isCompleted={isCompleted}
            countAllQuestions={questionsCount}
            countCompletedAnswers={completedAnswerCount}
            isRequired={isMandatory}
          />
        );
      });
    } else if (selectedSegment === 'all') {
      return <SurveysEmpty text={'survey_not_yet_received_all'} />;
    }

    return <SurveysEmpty text={'survey_not_yet_received_passed'} />;
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <FirstTimeVisit
            title="survey_surveys_questionnaires"
            description="survey_help_us"
            icon={{ name: 'documents' }}
            storageKey={SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SURVEYS_FIRST_TIME_VISIT}
          />

          <SettlementRating />

          <div className={s.container}>
            <Segmented
              options={SEGMENT_OPTIONS}
              value={selectedSegment}
              onChange={handleSegmentChange}
              fullWidth
            />
            <Divider />
            <div className={s.list}>
              <WithLoader isLoading={isEmployeeSurveysLoading || isAllSurveysLoading}>
                {renderTabs(selectedSegment === 'all' ? employeeSurveys : allSurveys)}
              </WithLoader>
            </div>
          </div>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default Surveys;
